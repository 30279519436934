<template>
  <Modal @close="closeModal">
    <template v-slot:modal-title>{{ $t("password.formTitle")}}</template>
    <template v-slot:modal-body>
        <p class="notification">
          {{$t("password.formDescription")}}<br/><br/>
          <ul class="password-conditions">
            <li :class="currentPassword.length > 0 ? 'has-text-success' : 'has-text-danger'">{{$t("password.requirements.currentPassword")}}</li>
            <li :class="hasUpperCase ? 'has-text-success' : 'has-text-danger'">{{ $t("password.requirements.capitalLetter")}}</li>
            <li :class="hasLowerCase ? 'has-text-success' : 'has-text-danger'">{{$t("password.requirements.lowercaseLetter")}}</li>
            <li :class="hasNumber ? 'has-text-success' : 'has-text-danger'">{{$t("password.requirements.number")}}</li>
            <li :class="hasLength ? 'has-text-success' : 'has-text-danger'">{{$t("password.requirements.length")}}</li>
            <li :class="hasNewPassword ? 'has-text-success' : 'has-text-danger'">{{$t("password.requirements.newPasswordTwice")}}</li>
          </ul>
        </p>
        <div class="field">
          <p class="control">
            <input
              :class="['input', userInputClass]"
              type="password"
              v-model="currentPassword"
              :placeholder="$t('password.currentPassword')"
            />
          </p>
          <p class="help is-danger" v-if="userInputClass">
            {{ $t("password.errors.currentPassword") }}
          </p>
        </div>
        <div class="field">
          <p class="control">
            <input
              :class="['input', userInputClass]"
              type="password"
              v-model="newPassword"
              :placeholder="$t('password.newPassword')"
            />
          </p>
          <p class="help is-danger" v-if="userInputClass">
            {{ $t("password.errors.newPassword") }}
          </p>
        </div>
        <div class="field">
          <p class="control">
            <input
              :class="['input', userInputClass]"
              type="password"
              v-model="newPasswordConfirm"
              :placeholder="$t('password.newPasswordConfirmation')"
            />
          </p>
          <p class="help is-danger" v-if="userInputClass">
            {{ $t("password.errors.newPasswordConfirmation") }}
          </p>
        </div>
      </template>
    <template v-slot:modal-footer>
        <div class="field is-grouped">
          <p class="control">
            <button class="button is-text" @click="reset">
              {{ $t("password.reset") }}
            </button>
          </p>
          <p class="control">
            <button :class="['button', 'is-success', isLoading ? 'is-loading': '']" @click="changePassword" :disabled="!isReady">
              {{ $t("password.save") }}
            </button>
          </p>
        </div>
    </template>
    </Modal>
</template>
<style scoped>
.notification {
  color: #000;
}
ul.password-conditions li.has-text-success:before {
  content: "\2713";
  display: inline-block;
  color: #48c774;
  padding: 0 6px 0 0;
}

ul.password-conditions li.has-text-danger:before {
  content: "\2717";
  display: inline-block;
  color: #f14668;
  padding: 0 6px 0 0;
}
</style>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  data() {
    return {
      currentPassword: "",
      newPassword: "",
      newPasswordConfirm: "",
      userInputClass: "",
      isLoading: false,
    };
  },
  components: {
    Modal: () => import("@/components/Modal.vue"),
  },
  computed: {
    ...mapState({
      isActive: (state) => state.modals.changePassword.isActive,
    }),
    hasLowerCase: function () {
      return this.newPassword.search(/[a-z]/) !== -1;
    },
    hasUpperCase: function () {
      return this.newPassword.search(/[A-Z]/) !== -1;
    },
    hasNumber: function () {
      return this.newPassword.search(/[0-9]/) !== -1;
    },
    hasLength: function () {
      return this.newPassword.length >= 8;
    },
    hasNewPassword: function () {
      return (
        this.newPassword !== "" &&
        this.newPassword === this.newPasswordConfirm &&
        this.newPassword !== this.currentPassword
      );
    },
    isReady: function () {
      if (
        this.hasNewPassword &&
        this.hasLowerCase &&
        this.hasUpperCase &&
        this.hasNumber &&
        this.hasLength
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    ...mapMutations(["SET_MODAL_STATE_CHANGE_PASSWORD"]),
    changePassword: function () {
      this.isLoading = true;
      var formData = new FormData();
      formData.append("currentPassword", this.currentPassword);
      formData.append("newPassword", this.newPassword);
      formData.append("op", "update_own_password");

      this.$http.post("update-own-password/", formData).then(() => {
        this.isLoading = false;
        this.$showToastWithType(this.$t("password.success"),"success")
        this.SET_MODAL_STATE_CHANGE_PASSWORD(false);
        this.reset();
      });
    },
    reset: function () {
      this.currentPassword = "";
      this.newPassword = "";
      this.newPasswordConfirm = "";
    },
    closeModal: function () {
      this.reset();
      this.$emit("close");
    },
  },
};
</script>

<style>
</style>
